import { AccountCenter } from '@/components/sgwt/AccountCenter.tsx';
import { NavLink } from "react-router-dom";
import { updateTheme } from '@/utils/theme.ts';
import { type ReactElement, useState } from 'react';
export function Header(): JSX.Element {

  const [theme, setTheme] = useState('system');

  const changeTheme= (): void => {
    //const theme = window.SGWTWidgetConfiguration.context?.getSgDesignSystemTheme();
    updateTheme(theme == 'dark' ? 'system' : 'dark');
    setTheme(theme == 'dark' ? 'system' : 'dark');
    // const hasThemeChanged = true;//isDarkMode !== (variation === 'dark');
    // const accountCenter = document.querySelector<AccountCenterThemeSwitcher>('sgwt-account-center');
    // if (hasThemeChanged) {
    //   const accountCenter = document.querySelector<AccountCenterThemeSwitcher>('sgwt-account-center');
    //   if (typeof accountCenter?.changeTheme === 'undefined') {
    //     accountCenter?.addEventListener(
    //       'sgwt-account-center--ready',
    //       () => {
    //         accountCenter?.changeTheme('dark');
    //         //accountCenter?.changeTheme(isDarkMode ? 'dark' : 'standard');
    //       },
    //       { once: true },
    //     );
    //   } else {
    //     accountCenter?.changeTheme('dark');
    //   }
    //   updateTheme('dark');
    // }
  }

  return (
    <header className="navbar border-bottom">
      <div className="navbar-title">
        <a className="navbar-title-link" href="/">
          <div className="navbar-logo">
            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0" y="0" width="32" height="32" fill="white" />
              <rect x="1" y="1" width="30" height="15" fill="var(--bs-socgen)" />
              <rect x="1" y="16" width="30" height="15" fill="black" />
              <rect x="6" y="15" width="20" height="2" fill="white" />
            </svg>
          </div>
          <div className="navbar-title-divider" />
          <div className="navbar-service-name">
            SGM Status
          </div>
        </a>
      </div>

      <div className="navbar-content">
        <ul className="navbar-navigation m-0">
          <li className="navbar-item">
          <NavLink
            to="/"
            className={({ isActive, isPending }) =>
              isPending ? "navbar-link pending" : isActive ? "navbar-link active" : "navbar-link"
            }
          >
            Home
          </NavLink>
          </li>
          <li className="navbar-item">
          <NavLink
            to="/services"
            className={({ isActive, isPending }) =>
              isPending ? "navbar-link pending" : isActive ? "navbar-link active" : "navbar-link"
            }
          >
            SGM Services: Subscription dependencies
          </NavLink>
          </li>
        </ul>
      </div>

      <div className="navbar-toolbar">
        <button type="button" className="h-100 btn btn-flat-primary btn-icon btn-nav btn-lg p-2" data-e2e="help-request-btn" onClick={changeTheme}><i className="icon icon-md">contrast</i></button>
        <AccountCenter />
      </div>
    </header>
  );
}
