import { getAuthorizationHeader } from '../sgwt/sgwtConnect.ts'
import { type HealthGraphDto } from '../models/healthGraph.ts'
import { getConfig } from '@/config/config.ts';


export class HealthGraphService {
  get(key?: string, env?: string): Promise<HealthGraphDto> {

    const { apiUrl } = getConfig();
    const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      } as RequestInit;

    return fetch(`${apiUrl}/api/v2/webapps/${key}/graphs/by-healthchecks/${env}`, requestOptions)
    .then((_response: Response) => {
        return _response.json();
    });
  }
}