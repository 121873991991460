import type { ServiceGraphDto } from '@/models/healthGraph.ts';
import React, { type FC, type ReactNode } from 'react';
import { Button } from 'reactstrap';
import './panelDetails.css'

interface Props {
    service: ServiceGraphDto | undefined;
    closePanel: () => void;
}


const Pill: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <span
      className="rounded-circle bg-light bg-opacity-40 d-flex align-items-center justify-content-center m-2"
      style={{ width: '3rem', height: '3rem' }}
    >
      {children}
    </span>
  );
};

const ButtonPill: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <span
      className="text-primary fw-standard badge rounded-pill bg-light bg-opacity-40 d-flex align-items-center justify-content-center m-2 px-3"
      style={{ height: 48 }}
    >
      {children}
    </span>
  );
};

const PanelDetails: FC<Props> = ({
  service,
    closePanel,
}: Props) =>
{

  return  service != null ? 
  <div className='panel-bordered'>
    <Button color="white float-end btn-icon btn-top" onClick={closePanel}>
        X
    </Button>
    <h6>{service.name}</h6>

    <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-2">
            <label className="form-label mb-1" htmlFor="department">
              <span className="text-nowrap">Manager department</span>
            </label>
            <div className="w-100">
              <span role="button" id="department">
                {service.apiDeclaration?.organizationalUnit}
              </span>
            </div>
          </div>
        </div>
        <div className="col-6">
        </div>
      </div>
    <div className="row">
        <div className="col-">
          <div className="form-group w-100 mb-0 mt-2">
            <label className="form-label mb-1" htmlFor="manager">
              <span className="text-nowrap">Manager</span>
            </label>
            <div className="w-100">
              <span role="button" id="manager">
              {service.apiDeclaration?.manager}
              {service.apiDeclaration?.managerBackups.map(manager => <div>{manager}</div>)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="application">
              <span className="text-nowrap">Application</span>
            </label>
            <div className="w-100">
              <span role="button" id="application">
                <a href={'https://developer.fr.world.socgen/explore/application/'+service?.apiDeclaration?.kearId} target='_blank'>{service?.apiDeclaration?.iAppliName}</a>
              </span>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="sginitiative">
              <span className="text-nowrap">SG Initiative</span>
            </label>
            <div className="w-100">
              <a href="/" id="sginitiative">
                Open project
              </a>
            </div>
          </div>
        </div>
      </div>

      
      <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="application">
              <span className="text-nowrap">Exposition</span>
            </label>
            <div className="w-100">
              {service?.apiDeclaration?.exposition}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="sginitiative">
              <span className="text-nowrap">Lifecycle</span>
            </label>
            <div className="w-100">
              {service?.apiDeclaration?.lifeCycle}
            </div>
          </div>
        </div>
      </div>
      
      <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="application">
              <span className="text-nowrap">RTO</span>
            </label>
            <div className="w-100">
              {service?.apiDeclaration?.formattedRto}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="sginitiative">
              <span className="text-nowrap">RPO</span>
            </label>
            <div className="w-100">
              {service?.apiDeclaration?.formattedRpo}
            </div>
          </div>
        </div>
      </div>


      <div className="mt-4 d-flex flex-wrap">
        {/* <Pill>
          <KibanaIcon width={25} height={25} />
        </Pill>
        <Pill>
          <ArgoCdIcon width={25} height={25} />
        </Pill>
        <Pill>
          <GithubIcon width={25} height={25} />
        </Pill>
        <Pill>
          <GithubActionIcon width={25} height={25} />
        </Pill>
        <Pill>
          <AzureIcon width={25} height={25} />
        </Pill>
        <Pill>
          <SonarQubeIcon width={25} height={25} />
        </Pill> */}
        <ButtonPill><i className="icon icon-sm me-1">public</i><a href={`https://platformization.sgmarkets.world.socgen/explore/all?names=${service?.apiDeclaration?.iAppliName}`} target='_blank'>Platformization</a></ButtonPill>
        {service?.apiDeclaration?.sourceRepository == null ? null : 
          <ButtonPill><i className="icon icon-sm me-1 github-icon"></i><a href={`${service?.apiDeclaration?.sourceRepository}`} target='_blank'>Github</a></ButtonPill>
        }
        <ButtonPill>
          <i className="icon icon-sm me-1">web_asset</i>
          <a href={`https://developer.sgmarkets.com/explore/api/${service?.apiDeclaration?.key}/v1/information`} target='_blank'>Api</a>
        </ButtonPill>
      </div>
      <h6 className='mt-3'>Health:</h6>
      <div className="mt-4 d-flex flex-wrap">
        { service?.healthUri != '' ? <a target='_blank' href={service?.healthUri!}><img src="/chart/health.svg" width={32} height={32}/>{service.healthUri}</a> : <></>}
      </div>
      <h6 className='mt-3'>Infrastructure:</h6>
      <div className="mt-4 d-flex flex-wrap">
        {service?.instances?.map(instance => {
          return instance.status == "Healthy" ? <div><span><i className="icon icon-md text-success">check_circle</i></span>{instance.name}</div> :
          instance.status == "Unhealthy" ? <div><span><i className="icon icon-md text-danger">check_circle</i></span>{instance.name}</div> : 
          <div><span><i className="icon icon-md text-warning">check_circle</i></span>{instance.name}</div>
        })}
      </div>
  </div>
  : null;
}
export default PanelDetails;