import { type ReactElement, useEffect, useState } from 'react';
import { ApplicationsService } from '@/services/ApplicationsService.ts'
import { ApiConfigurationService } from '@/services/ApiConfigurationService.ts'
import { type ApplicationsDto, type ApplicationDto } from '@/models/services.ts'
import { type APIEntryDto } from '@/models/apiConfiguration.ts'
import Bookmarks from './bookmarks.tsx'
import ApplicationCard from './applicationCard.tsx'
import SettingsForm from './settingsForm.tsx'
import Loading from '../common/Loading.tsx';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export function ConnectedHome(): ReactElement {

    const [applications, setApplications] = useState<ApplicationsDto | null>(null);
    const [bookmarks, setBookmarks] = useState<ApplicationDto[] | null>(null);
    const [filter, setFilter] = useState('');
    const [filterDepartment, setFilterDepartment] = useState('');
    const [filterHealth, setFilterHealth] = useState('');
    const [filterHosting, setFilterHosting] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalSettings, setModalSettings] = useState(false);
    const [selectedApplication, setSelectedApplication] = useState<ApplicationDto | null>(null);

    const RefreshData = () => {
        setIsLoading(true);
        new ApplicationsService().get().then((applications: ApplicationsDto) => {
            const bookmarks = applications?.webApps
                ?.filter(
                  (f) =>
                    f.isBookmarked
                )
            setIsLoading(false);
            setBookmarks(bookmarks);
            setApplications(applications);
        });
    };
    

  const ToggleBookmarks = (application: ApplicationDto, currentStatus: boolean) => {
    
    new ApplicationsService().toggleBookmarks(application.key, currentStatus).then((result: boolean) => {
        RefreshData();
    });
  }

  const EditSettings = (application: ApplicationDto) => {
    setSelectedApplication(application);
    setModalSettings(!modalSettings);
  };

  const CloseSettings = () => setModalSettings(!modalSettings);

  const SaveSettings = (producerCode: string | undefined, apiEntries : APIEntryDto[]) => {
    new ApiConfigurationService().saveSettings(selectedApplication, producerCode, apiEntries);
    CloseSettings();
    RefreshData();
  };
  
  

    useEffect(() => {
        RefreshData();
    }, []);
  
    return (
        <div>
            <div className="row pt-5  d-flex">
                <div
                    style={{ position: 'relative', height: '35px' }}
                    className="w-100"
                >
                    <h4>Bookmarks</h4>
                    <div className="with-line"></div>
                </div>
                <Loading isLoading={isLoading} />
                {isLoading == false ? <Bookmarks bookmarks={bookmarks} /> : <></>}
                
            </div>

            <div className="row pt-5  d-flex">
                <div className='service-with-line'>
                <div className="with-line"></div>
                <h4>SGM Services</h4>
                </div>
                <div className="input-group">
                    <div className="col-md input-group  p-2">
                        <label className="form-label">Filter</label>
                        <input
                        type="text"
                        className="form-control w-100"
                        id="filter"
                        name="filter"
                        value={filter}
                        onChange={(event) => setFilter(event.target.value)}
                        ></input>
                    </div>
                    <div className="col-md input-group  p-2">
                        <label className="form-label">Health</label>
                        <select className="form-select w-100" id='select_health'
                            onChange={(event) => {
                                debugger;
                                setFilterHealth(event.target.value)}
                            }>
                            <option value="">All</option>
                            <option value="">All</option>
                            <option value="Healthy">Healthy</option>
                            <option value="Unhealthy">Unhealthy</option>
                            <option value="Degraded">Degraded</option>
                        </select>
                    </div>
                </div>
                <div className="input-group">
                    <div className="col-md input-group  p-2">
                        <label className="form-label">Department</label>
                        <select className="form-select w-100" id='select_department'
                            onChange={(event) => {
                                debugger;
                                setFilterDepartment(event.target.value)}
                            }>
                            <option value="">All</option>
                            <option value="GBTO/CIO/SGM/DPO">GBTO/CIO/SGM/DPO</option>
                            <option value="GBTO/CIO/SGM/SOL">GBTO/CIO/SGM/SOL</option>
                            <option value="GBTO/CIO/SGM/DCI">GBTO/CIO/SGM/DCI</option>
                        </select>
                    </div>
                    <div className="col-md input-group  p-2">
                    <label className="form-label">Hosting</label>
                        <select className="form-select w-100" id='select_hosting'
                            onChange={(event) => {
                                debugger;
                                setFilterHosting(event.target.value)}
                            }>
                            <option value="">All</option>
                            <option value="AWS">AWS</option>
                            <option value="Azure">Azure</option>
                            <option value="DTZone">DT-Zone</option>
                            <option value="LAN">LAN</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row pt-5  d-flex">
                <Loading isLoading={isLoading} />
                {isLoading == false && applications?.webApps
                ?.filter(
                    (f) =>
                    (f.name.toLowerCase().includes(filter.toLowerCase()) ||
                    f.managerDepartment.toLowerCase().includes(filter.toLowerCase()) ||
                    filter === '')
                    && (filterDepartment === '' || (filterDepartment != '' && f.managerDepartment.toLowerCase().includes(filterDepartment.toLowerCase())) )
                    && (filterHealth === '' || (filterHealth != '' && f.status.toLowerCase().includes(filterHealth.toLowerCase())) )
                    && (filterHosting === '' || (f.hosting !== null && filterHosting != '' && f.hosting.toLowerCase().includes(filterHosting.toLowerCase())) )
                )
                .map((service) => (
                    <div className="col-12 col-lg-6 col-xl-4 d-flex">
                    <ApplicationCard service={service} refreshData={RefreshData} toggleBookmarks={ToggleBookmarks} editSettings={EditSettings}></ApplicationCard>
                    </div>
                ))}
            </div>
            <Modal isOpen={modalSettings} size='xl'>
                <SettingsForm application={selectedApplication} closeModal={CloseSettings} save={SaveSettings} />
            </Modal>
        </div>
    );
}