import { type ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationsService } from '@/services/ApplicationsService.ts'
import { HealthGraphService } from '@/services/HealthGraphService.ts'

import { type ApplicationsDto, type ApplicationDto } from '@/models/services.ts'
import { type HealthGraphDto, type ServiceGraphDto } from '@/models/healthGraph.ts'
import Loading from '../common/Loading.tsx';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/common/Breadcrumb.tsx'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Label, Input, Nav, NavLink, NavItem,TabContent,TabPane, Row, Col, Card, CardTitle, CardText  } from 'reactstrap';
import HealthTree from './healthTree.tsx'

export function HealthChart(): ReactElement {

    let { key } = useParams();
    let { env } = useParams();
    
    const [application, setApplication] = useState<ApplicationDto>();
    const [currentEnvironment, setCurrentEnvironment] = useState<string>();
    const [healthGraphDto, setHealthGraphDto] = useState<ServiceGraphDto>();
    
    useEffect(() => {
        new ApplicationsService().get().then((applications: ApplicationsDto) => {
            const app = applications?.webApps
                ?.filter(
                  (f) =>
                    f.key == key
                )
            setApplication(app[0]);
            console.log('env:'+env);
            let currentEnv = env;
            if (env == undefined)
                currentEnv =  app[0].environments[0].name
            setCurrentEnvironment(currentEnv);
            new HealthGraphService().get(key, currentEnv).then((healthGraph: HealthGraphDto) => {
                setHealthGraphDto(healthGraph.service)
            })
        });
    }, []);
  
    return (

        <div>
            <Breadcrumb pageName={application?.name!} parentName='Home' parentLink='/' />
            <Nav tabs>
                {application?.environments.map((environment) =>(
                    <NavItem key={`nav_${environment.name}`} >
                        <NavLink href={`/healths/${key}/${environment.name}`} key={environment.name} className={currentEnvironment == environment.name ? "active" : '' }>{environment.name}</NavLink>
                    </NavItem>
                ))
                }
            </Nav>
            <div>
                <HealthTree service={healthGraphDto!} />
            </div>
        </div>
    );
}