import { type ReactElement, type FC, useEffect, useState } from 'react';
import { type DataGraph, type GraphItem } from '@/models/dataGraph.ts'
import { type Instance, type ServiceGraphDto, type ApplicationLink } from '@/models/healthGraph.ts'
import OrgChartComponent from './healthOrgChart.tsx'
import { Row, Col  } from 'reactstrap';
import PanelDetails from './panelDetails.tsx';
import type { ApplicationDto } from '@/models/services.ts';


  
export type HealthTreeProps = {
    service: ServiceGraphDto
  };

  
const HealthTree: FC<HealthTreeProps>= ({
    service,
}: HealthTreeProps) => {

    const [data, setData] = useState<DataGraph[]>();
    const [dataGraph, setDataGraph] = useState<DataGraph | null>(null);
        
    const ParseChild = (parentId:string , array: DataGraph[], child: ServiceGraphDto) => {
        const item = {} as DataGraph;

        item.id = child.name;
        item.key = child.key;
        item.parentId = parentId;
        item.name = child.name;
        item.status = child.status;
        item.service = child;
        item.type = GetType(child.type);
        item.links = [];
        child.applicationLinks?.map((link: ApplicationLink) => {
            item.links.push({ name: link.type, icon: getLinkIcon(link.type), link: link.url });
        });
        item.nodes= [];
        child.instances?.map((instance: Instance)  => {
            item.nodes?.push(instance.status);
        });
        array.push(item);

        child.children?.map(_child =>{
            ParseChild(item.id, array, _child);
        });

    }

    const ComputeDataGraph = (service: ServiceGraphDto) => {

        const array: DataGraph[] = [];
        const graphItems: GraphItem[] = [];
        const item = {} as DataGraph;
        if (service != null) {
            item.id = service.name;
            item.key = service.key;
            item.name = service.name;
            item.status = service.status;
            item.service = service;
            item.type = GetType(service.type);
            item.links = [];
            service.applicationLinks?.map((link: ApplicationLink) => {
                item.links.push({ name: link.type, icon: getLinkIcon(link.type), link: link.url });
            });
            array.push(item);

            service.children?.map((child: ServiceGraphDto) =>{
                ParseChild(item.name, array, child);
            });
            setData(array);
        }
    }

    const GetType = (type: string) => {
        if (type === "web-apps") {
            return "/chart/cdn.png";
        }
        if (type === "website") {
            return "/chart/cdn.png";
        }
        if (type === "api") {
            return "/chart/api.png";
        }
        if (type === "database") {
            return "/chart/db.png";
        }
        if (type === "frontdoor") {
            return "/chart/frontdoor.svg";
        }
        if (type === "apim") {
            return "/chart/apim.svg";
        }
        if (type === "appgateway") {
            return "/chart/appgateway.svg";
        }
        return "/chart/health.svg";
    }

    const getLinkIcon = (type: string) => {
        switch(type) {
            case 'do-it-now':
                return "din.png";
            default:
                return type+".svg"
        }
    } 
    
    const onNodeClick = (d: any) => {
        setDataGraph(d.data);
    }
    const closePanel = (): void => {
        setDataGraph(null);
    }

    useEffect(() => {
        ComputeDataGraph(service);
    }, [service]);


    return (
        <>
        <PanelDetails service={dataGraph?.service} closePanel={closePanel} />
        {data != null ? <OrgChartComponent data={data} onNodeClick={onNodeClick} /> : null }
        </>
  );
};

export default HealthTree;

